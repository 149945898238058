



jQuery.noConflict()(function($){
    $('#collage .vlt-collage-inner').collagePlus({
        'allowPartialLastRow': false,
        'effect' : 'effect-2',
        'direction': 'vertical',
        // 'targetHeight': 340,
    });
        
});

jQuery.noConflict()(function($){
    var gridContainer = $('#cubeportfolio5');
    gridContainer.imagesLoaded(function(){
        gridContainer.cubeportfolio({
            defaultFilter: '*',
            filters: '.vlt-portfolio-grid-filters',
            animationType: 'fadeOut',
            layoutMode: 'grid', //mosaic
                sortToPreventGaps: true,
            gapHorizontal: 30,
            gapVertical: 30,
            gridAdjustment: 'responsive',
            mediaQueries:
            [{
                width: 1170,
                cols: 2,
            }, {
                width: 991,
                cols: 2,
            }, {
                width: 767,
                cols: 2,
            }, {
                width: 575,
                cols: 1,
            }],
            displayType: 'default',
            displayTypeSpeed: 150,
        });
    });
});




jQuery.noConflict()(function($){
    var gridContainer = $('#cubeportfolio6');
    gridContainer.imagesLoaded(function(){
        gridContainer.cubeportfolio({
            defaultFilter: '*',
            filters: '.vlt-portfolio-grid-filters_gg',
            animationType: 'fadeOut',
            layoutMode: 'grid', //mosaic
            sortToPreventGaps: true,
            gapHorizontal: 20,
            gapVertical: 20,
            gridAdjustment: 'responsive',
            mediaQueries:
            [{
                width: 1170,
                cols: 1,
            }, {
                width: 991,
                cols: 1,
            }, {
                width: 767,
                cols: 1,
            }, {
                width: 575,
                cols: 1,
            }],
            displayType: 'default',
            displayTypeSpeed: 150,
        });
    });
});





